<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <div class="search-results">
                {{ items.total }} Resultados encontrados
              </div>
            </div>
            <div class="view-options  d-none d-lg-block">
              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary">
                <b-form-radio v-for="option in itemViewOptions" :key="option.value" :value="option.value">
                  <feather-icon :icon="option.icon" size="18" />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="body-content-overlay"></div>

    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-form @submit.prevent="getProducts()">
          <b-input-group class="input-group-merge">
            <b-form-input v-model="search" placeholder="Buscar Productos" class="search-product"/>
            <b-input-group-append is-text @click="getProducts()">
              <feather-icon icon="SearchIcon" size="25" class="text-muted cursor-pointer"/>
            </b-input-group-append>
          </b-input-group>
          </b-form>
        </b-col>
      </b-row>
    </div>
    <!-- Prodcuts -->
     <b-overlay
      :show="loadingSearchCategory"
      rounded="sm"
      style="height: 100%; width: 100%;"
    > 
    <b-card id="boxCategory" no-body class="mt-1 pt-1 pl-1 d-lg-none">
      <vuescroll :ops="ops">
      <div class="d-flex child-element pb-1">
        <div class="mr-1 square-btn">
          <b-button
            :variant="searchCategory !== null ? 'outline-primary': 'primary'"
            pill
            class="mr-1 text-nowrap  "
            style="width: 100%; height: 84%;"
            @click="selectCategory(null)"
          >
            Todos
          </b-button>
        </div>
        <div 
          v-for="(category, indexTr) in categoriesOptions"
          :key="indexTr"
          class="d-flex flex-column align-items-center justify-content-center mr-1 square-btn"
        >
          <b-img
            :alt="`category-${category.id}`"
            rounded="circle"
            style="width: 100px; height: 100px;"
            :src="category.image"
            @click="selectCategory(category.id)"
          />
          <span class="text-muted text-truncate text-capitalize">{{category.name}}</span>
        </div>
      </div>
      </vuescroll>
    </b-card>
    </b-overlay>
   
    <b-overlay
      :show="loadingSearchProduct"
      rounded="sm"
      style="height: 100%; width: 100%;"
    > 
    <section id="boxProduct" :class="itemView">
      <b-card  v-for="product in items.data" :key="product.id" class="ecommerce-card" no-body>
        <div class="item-img text-center d-flex justify-content-center">
          <b-link>
            <b-img
              :alt="`${product.name}-${product.id}`"
              fluid
              class="card-img-top card-img"
              :src="product.image"
            />
          </b-link>
        </div>
        <b-card-body>
          <div class="item-wrapper d-block d-md-flex">
            <div class="item-name">
              <b-link
                class="text-body">
                {{ product.name }} 
              </b-link>
            </div>
            <div>
              <h6 class="item-price text-nowrap">{{ product.price | currency({ symbol: '$', precision: 2 }) }}</h6>
            </div>
          </div>
          <div v-if="product.stock" class="item-wrapper">
            <span >
              Disponibles <strong>{{ product.stock }}</strong>
            </span>
          </div>
        </b-card-body>
        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            @click="handleCartActionClick(product)">
            <feather-icon icon="ShoppingCartIcon" class="mr-50" />
            <span>{{ product.isInCart ? 'Ver Carrito' : 'Agregar al Carrito' }}</span>
          </b-button>
        </div>
      </b-card>
    </section>
    </b-overlay>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="items.current_page"
            :total-rows="items.total"
            :per-page="items.per_page"
            first-number
            @change="getProducts"
            align="right"
            last-number
            prev-class="prev-item"
            next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <div class="sidebar-detached sidebar-left">
        <div class="sidebar">
          <div class="sidebar-shop">
            <b-row>
              <b-col cols="12">
                <h6 class="filter-heading d-none d-lg-block">
                  Categorias
                </h6>
              </b-col>
            </b-row>
            <b-card>
              <div class="product-categories">
                <b-form-radio v-model="searchCategory" :value="null" class="mb-1">Todos</b-form-radio>
                <b-form-radio-group
                  v-model="searchCategory"
                  class="categories-radio-group"
                  stacked
                  :options="categoriesOptions"
                  text-field="name"
                  value-field="id"/>
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  VBTooltip,
  BForm,
  BOverlay
} from 'bootstrap-vue'
import vuescroll from 'vuescroll'

import Ripple from 'vue-ripple-directive'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { useShopUi } from './useECommerceShop'
import Push from 'push.js'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    // BSV
    BOverlay,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BForm,
    vuescroll,
  },
  data: () => ({
    items: {},
    search: null,
    searchCategory: null,
    categoriesOptions: [],
    loadingSearchProduct: false,
    loadingSearchCategory: false,
    ops: {
        vuescroll: {},
          scrollPanel: {},
          rail: {
             background: '#01a99a',
          },
          bar: {
            background: '#ee3926'
          },
      },
      timeOutSearch: null,
  }),
  computed: {
    notification() {
      return this.$store.getters['user/userData'].notifications
    },
    userId() {
      return this.$store.getters['user/userData'].id
    },
    cartLength(){
      return this.$store.state['app-ecommerce'].cartItems.length
    },
  },
  methods: {
    getDay(day) {
      switch (day) {
        case 1:
          return 'Lunes'
        case 2:
          return 'Martes'
        case 3:
          return 'Miercoles'
        case 4:
          return 'Jueves'
        case 5:
          return 'Viernes'
      }
    },
    async userNotifications(){
      if(this.notification.length > 0) {
        this.notification.forEach(item => {
          const res = {...item, onClose: async () => {
           await this.$store.dispatch('notifications/close', {notificationId: item.id, params: {private: 1}})
           .then(async () => {
             await this.$store.dispatch('user/getUserData')
           })
          }}
          Push.create('Andino App', res)
        })
      }
    },
    async getCategories() {
      try{
        this.loadingSearchCategory = true
         this.categoriesOptions = await this.$store.dispatch('products/indexCategory', {paginate: false});
      }catch(e){
        console.log(e)
      }finally{
        this.loadingSearchCategory = false
      }     
    },
    async getProducts(page = 1) {
      try {
        this.loadingSearchProduct = true;
        this.items = await this.$store.dispatch('products/index', { page: page, search: this.search, searchCategory: this.searchCategory })
      } catch (error) {
        console.log(error)
      }finally{
        this.loadingSearchProduct = false;
      }
    },
    handleCartActionClick(product){
      if (product.isInCart) {
        this.$router.push({ name: 'apps-e-commerce-checkout' })
      } else {
        product.isInCart = true
        this.$store.commit('app-ecommerce/UPDATE_PRODUCT_CART', product)
        this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.$store.state['app-ecommerce'].cartItemsCount + 1)
        localStorage.setItem('cartItems', JSON.stringify(this.$store.state['app-ecommerce'].cartItems))
      } 
    },
    selectCategory(id){
    if (window.innerWidth < 992) {
      window.scrollTo(0, 177)  
    } 
      this.searchCategory = id  
    }
  },
  mounted(){
    this.getCategories()
    this.getProducts()
    this.userNotifications()

    this.$store.dispatch('app-ecommerce/fetchCartProducts')

    window.onscroll = function() {runScroll()}

    const boxCategory = document.getElementById('boxCategory')
    const boxProduct = document.getElementById('boxProduct')
    function runScroll() {
      if (window.pageYOffset >= 172) {
        boxCategory.classList.add('sticky')
        if (window.innerWidth < 992) {
          boxProduct.classList.add('mt-5', 'pt-5')
        }
      } else {
        boxCategory.classList.remove('sticky')
        if (window.innerWidth < 992) {
          boxProduct.classList.remove('mt-5', 'pt-5')
          boxProduct.classList.add('mt-1')
        }
      }
    }
  },
  watch: {
    searchCategory(){
      this.getProducts()
    },
    search(){
      clearTimeout(this.timeOutSearch)

      this.timeOutSearch = setTimeout(() => {
        this.getProducts()
      }, 800)
    }
  },
  setup() {
    const { itemView, itemViewOptions } = useShopUi()
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      itemViewOptions,
      itemView,
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
.sticky{
  position: fixed;
  top: 72px;
  left: 0;
  right: 0;
  z-index: 1;
  margin: 15px;
}
#boxCategory{
  overflow: hidden;
}
#marginProduct{
  margin-top: 1000px !important;
}
</style>
